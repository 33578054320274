<template>
  <div>
      <card-login/>
  </div>
</template>

<script>
import cardLogin from '../components/Cards/CardLogin.vue'
export default {
  components: {
      cardLogin
  },
  setup() {
      return {

      }
  }
}
</script>

<style>
html, body {
  margin: 0; 
  padding: 0;
  background: white;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(18,170,16,1) 51%, rgba(249,249,0,1) 100%);
  height: 100%;
}
</style>