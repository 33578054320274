<template>
  <div class="contenedor">
    <br><br>
    
    <iframe src="https://app.powerbi.com/view?r=eyJrIjoiNTY1ZmM0ODctYjM5Ny00ZWNiLTliYzYtODczMGUyMWVhYTljIiwidCI6ImI4YThhYTE3LTc3ZmMtNDY2Ny05MWI3LWRkOGE0YjZjMWVlNSJ9" frameborder="0" class="iframe"></iframe>
  </div>

</template>
<script>

export default {
  name: "dashboard-page",
  components: {

  },
};
</script>
<style scoped>
.contenedor {
  width: 100%;
  height: 100vh; /* 100% de la altura del viewport */
  overflow: hidden; /* para ocultar barras de desplazamiento si el contenido del iframe es más grande */
}

.iframe {
  width: 100%;
  height: 90%;
  border: none; /* quitar el borde del iframe si lo deseas */
}

</style>