import { createStore } from 'vuex'
import axios from 'axios';

export default createStore({
  state: {
    loginUser: {
      msgError: '',
      user: {},
      token: ''
    },
    listUsers: {
      msgError: '',
      users: [],
    }
  },
  getters: {
  },
  mutations: {

    'CLEAR_DATA_LOGIN': (state) => {
      state.loginUser = {
        msgError: '',
        user: {},
        token: ''
      }
    },

    'LOGIN_ERROR': (state, payload) => {
      state.loginUser.msgError = payload

    },
    'LOGIN_SUCCESS': (state, payload) => {
      
      state.loginUser.user = payload.usuario
      state.loginUser.token = payload.token
      state.loginUser.msgError = ''
    },
    'LOAD_USERS_SUCCESS': (state, payload) => {
      state.listUsers.users = payload.usuarios;
      state.listUsers.msgError = '';
    },
    'LOAD_USERS_ERROR': (state, payload) => {
      state.listUsers.msgError = payload
    },
  },
  actions: {
    async reloadedPage({commit}, data) {
      await axios.get(`${process.env.VUE_APP_RUTA_API}api/auth`,
        {headers: {'x-token': data.token}}
        ).then((response) => {
          const info = {
            token: data.token, 
            usuario: response.data.usuario
          } 
          commit('LOGIN_SUCCESS',  info)
      })
      .catch((error) => {
        commit('LOGIN_ERROR', error.response.data.msg)
        console.error("Error al realizar la solicitud a la API:", error);
      });
    },
    deleteInfoBeforeLogin({commit}) {
      commit('CLEAR_DATA_LOGIN')
    }, 
    async validateUserLogin({commit}, credentials) {
      await axios.post(`${process.env.VUE_APP_RUTA_API}api/auth`,
        credentials
        ).then((response) => {
          const token = response.data.token;
          sessionStorage.setItem('x-token', token);
          const info = {
            token: token, 
            usuario: response.data.usuario
          } 
          commit('LOGIN_SUCCESS',  info)
      })
      .catch((error) => {
        commit('LOGIN_ERROR', error.response.data.msg)
        console.error("Error al realizar la solicitud a la API:", error);
      });
    },
    async loadUsers({commit}) {
      const token = sessionStorage.getItem('x-token');
      await axios.get(`${process.env.VUE_APP_RUTA_API}api/admin`,
        {headers: {'x-token': token}}
        ).then((response) => {
          const info = {
            token: token, 
            usuarios: response.data.usuarios
          } 
          commit('LOAD_USERS_SUCCESS', info)
      })
      .catch((error) => {
        commit('LOAD_USERS_ERROR', error.response.data.msg)
        console.error("Error al realizar la solicitud a la API:", error);
      });
    }
  },
  modules: {
  }
})
