import { createWebHistory, createRouter } from "vue-router";

// layouts
import Base from "@/layouts/Pantalla.vue";
import Auth from "@/layouts/Auth.vue";

// views for User layout
import Dashboard from "@/views/Dashboard.vue";
import Availability from "@/views/Availability.vue";
import Password from "@/components/Cards/CardChangePassword.vue"

// views for Admin layout
import CreateUser from "@/components/Users/CreateUser.vue"
import ListUsers from "@/components/Users/ListUsers.vue"
import EditUser from "@/components/Users/EditUser.vue"

// views for Auth layout
import Login from "../components/Cards/CardLogin.vue";

const routes = [
    {
      path: "/dashboard",
      redirect: "/",
      component: Base,
      meta: { requiresAuth: true },
      children: [
        {
          path: "/",
          component: Dashboard,
          name: 'dashboard'
        },
      ],
    },
    {
      path: "/dashboard",
      component: Base,
      meta: { requiresAuth: true },
      children: [
        {
          path: "availability/",
          component: Availability,
          name: 'availability'
        },
      ],
    },
    {
      path: "/user",
      component: Base,
      meta: { requiresAuth: true },
      children: [
        {
          path: "/password",
          component: Password,
          name: 'changePassword'
        },
      ],
    },
    {
      path: "/admin",
      component: Base,
      meta: { requiresAuth: true },
      children: [
        {
          path: "users/",
          component: ListUsers,
          name: 'listUsers'
        },
        {
          path: "create-user/",
          component: CreateUser,
          name: 'createUser'
        },
        {
          path: "edit-user/:id",
          component: EditUser,
          name: 'editUser'
        },
      ],
    },
    {
      path: "/",
      redirect: "/auth/login",
      component: Auth,
      children: [
        {
          path: "/auth/login",
          component: Login,
        }
      ],
    },
    { path: "/:pathMatch(.*)*", redirect: "/" },
  ];


  
  const router = createRouter({
    history: createWebHistory(),
    routes,
  });
  
  router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      const jwtToken = sessionStorage.getItem('x-token');
      if (!jwtToken) {
        // El usuario no está autenticado, redirige a la página de inicio de sesión
        next('/auth/login');
      } else {
        // El usuario está autenticado, permite el acceso a la ruta
          next();
      }
    } else {
      // La ruta no requiere autenticación, permite el acceso
      next();
    }
  });
export default router;