<template>
  <div class="pt-2">
    <button>
      <a
        class="text-blueGray-500 block"
        ref="btnDropdownRef"
        @click="toggleDropdown($event)"
      >
        <div class="items-center flex">
          <span
            class="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full"
          >
            <img
              alt="..."
              class="w-full rounded-full align-middle border-none shadow-lg"
              :src="image"
            />
          </span>
        </div>
      </a>
    </button>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-center rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
      @mouseenter="showDropdown"
      @mouseleave="hideDropdown"
      >
      {{ userLoggedIn.user.userName }}
      <hr class="mx-2"/>
      <router-link 
        :to="{name: 'changePassword'}"
        class="text-sm pb-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-decoration-none"
        >
        Cambiar Constraseña
      </router-link>
      <a
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-decoration-none"
        @click="logout" 
        >
        <button>
          Cerrar Sesión

        </button>
      </a>    
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import image from "@/assets/img/profile.png";
import store from '@/store';

export default {
  data() {
    return {
      dropdownPopoverShow: false,
      image: image,
    };
  },
  computed: {
    userLoggedIn() {
      return store.state.loginUser;
    },
  },

  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    logout: function () {
      sessionStorage.clear()
      localStorage.clear();
      this.$router.push('/auth/login');

    },
    showDropdown() {
      this.dropdownPopoverShow = true;
    },
    
    hideDropdown() {
      this.dropdownPopoverShow = false;
    },
  },
};
</script>
