<template>
    <div class="contenedor">
      <br><br>      
      <iframe src="https://app.powerbi.com/view?r=eyJrIjoiZjlhZDZlNjgtM2UzZS00N2YwLTlmNmEtYzUxM2IyYjJiZmIxIiwidCI6ImI4YThhYTE3LTc3ZmMtNDY2Ny05MWI3LWRkOGE0YjZjMWVlNSJ9" frameborder="0" class="iframe"></iframe>
    </div>
  </template>
  <script>
  
  export default {
    name: "availability-page",
    components: {
  
    },
  };
  </script>
  <style scoped>
  .contenedor {
    width: 100%;
    height: 100vh; /* 100% de la altura del viewport */
    overflow: hidden; /* para ocultar barras de desplazamiento si el contenido del iframe es más grande */
  }
  
  .iframe {
    width: 100%;
    height: 95%;
    border: none; /* quitar el borde del iframe si lo deseas */
  }
  
  </style>