<template>
	<div class="container">
		<div class="row w-75">
			<div class="col d-none d-lg-block col-md-4 col-lg-5 col-xl-6 rounded" >
				<img :src="imgLogin" alt="imagen con graficas" class="w-100">
			</div>
			<div class="col bg-white rounded p-5 col-md-8 col-lg-7 col-xl-6 ">
				<h2 class="fw-bold text-center py-3">Bienvenido</h2>
				<hr>
				<!-- login -->
				<div v-if="userLoged.msgError && !emailError " class="alert alert-danger" role="alert">
					{{ userLoged.msgError }}
				</div>
				<form action="was-validated">
					<div class="mb-4">
						<label for="email" class="form-label">Email</label>
						<input type="email" class="form-control" name="email" v-model="email" required @input="validateEmail">
						<div v-if="!email" class="text-bg-danger text-center"> introducir email</div>
						<div v-if="emailError" class="text-bg-danger text-center">No es un correo valido.</div>
			
					</div>
					<div class="mb-4">
						<label for="password" class="form-label">Password</label>
						<input type="password" class="form-control" name="password" v-model="password" required>
						<div v-if="!password" class="text-bg-danger text-center"> introducir password </div>
						<div v-if="password.length < 5 && password" class="text-bg-danger text-center"> Minimo 5 caracteres </div>
					</div>
					<div class="mb-4 form-check">
						<input type="checkbox" name="connected" class="form-check-input">
						<label for="connected" class="form-check-label">Recordarme</label>
					</div>
					<div class="d-grid" v-if="email && password.length > 4 && !emailError">
						<button type="button" class="btn btn-gradient-red fw-bold text-white" @click="verifyCredentials">
							Iniciar Sesión
						</button>
					</div>
					</form>
			</div>
		</div>
	</div>
  </template>
	
<script>
	import imgLogin from '@/assets/img/img_login.svg'
	import { useStore } from 'vuex';
	import { computed, ref} from 'vue';
	import { useRouter } from 'vue-router';

	export default {
	setup() {
		const router = useRouter();
		const store = useStore();
		const email = ref('');
		const password = ref('');
		const emailError = ref(false)
	

		const verifyCredentials = async () => {
			await store.dispatch('deleteInfoBeforeLogin');

			await store.dispatch('validateUserLogin', {email: email.value, password: password.value })
		
			if (userLoged.value.token){
				router.push({path: "/user/dashboard"});
			}
		};
		const userLoged = computed(() => store.state.loginUser);

		const validateEmail = () => {
			// Expresión regular para validar el formato de correo electrónico
			const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
			if (!emailRegex.test(email.value)) {
				emailError.value = true;
			} else {
				emailError.value = false;
			}
		}

		return {
			imgLogin,
			userLoged,
			verifyCredentials,
			email,
			password,
			validateEmail,
			emailError
		}

	}
	};
</script>

<style scoped>
	.container{
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.row {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.btn-gradient-red {
	background: #E32138;
	background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(18,170,16,1) 51%, rgba(249,249,0,1) 100%);
	}
</style>
  
  