<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Crear Usuario</h6>
        <div>
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button"
            @click="saveUser"
          >
            Save
          </button>
          <button
            class="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="submit"
          >
            <router-link class="text-white text-decoration-none" :to="{name: 'listUsers'}">

              Cancel
            </router-link>
          </button>

        </div>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <div class="alert alert-danger text-center mx-5" v-if="msgError" role="alert">
          {{ msgError }}
      </div>
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          User Information
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Username
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Username " v-model="formData.userName" required
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Email address
              </label>
              <input
                type="email"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Email address" v-model="formData.email"
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                First Name
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="First Name" v-model="formData.firstName"
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Last Name
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Last Name" v-model="formData.lastName"
              />
            </div>
          </div>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Password
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Password
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Password" v-model="formData.password"
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Confirm Password
              </label>
              <input
                type="email"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Confirm Password" v-model="formData.passwordConfirm"
              />
            </div>
          </div>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          información Importante
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Company
              </label>
              <select 
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                v-model="formData.company" 
                >
                <option disabled selected>Seleccione...</option>
                <option v-for="(com, index) in companies" :key="index" :value="com.id">{{ com.name }}</option>
              </select>
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Roles
              </label>
              <div class="form-check form-check-inline" v-for="(role, index) in roles" :key="index" >
                <input 
                  class="form-check-input" 
                  type="checkbox" 
                  :id="'inlineCheckbox' + index"
                  v-model="formData.roles"
                  :value="role.id">
                <label class="form-check-label" :for="'inlineCheckbox' + index"> {{ role.name }}</label>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                State
              </label>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked disabled>
                <label class="form-check-label" for="flexSwitchCheckChecked">Activo</label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

</template>
<script>
import { onMounted, ref } from 'vue';
const { useRouter } = require('vue-router');
import axios from 'axios';

export default {
  setup() {
    const formData = ref({
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      password: '',
      passwordConfirm: '',
      roles: [],
      company: '',
    })
    const roles = ref([]);
    const companies = ref([]);
    const msgError = ref('');
    const token = ref(sessionStorage.getItem('x-token'));
    const router = useRouter();


    const fetchRoles = async () => {
      await axios.get(`${process.env.VUE_APP_RUTA_API}api/rol`,
        {headers: {'x-token': token.value}}
        ).then((response) => {
          roles.value = response.data.roles
      })
      .catch((error) => {
        msgError.value = error.response.data.msg;
        console.error("Error al realizar la solicitud a la API de roles:", error);
      });
    }

    const fetchCompanies = async () => {
      await axios.get(`${process.env.VUE_APP_RUTA_API}api/company`,
        {headers: {'x-token': token.value}}
        ).then((response) => {
          companies.value = response.data.companies
      })
      .catch((error) => {
        msgError.value = error.response.data.msg
        console.error("Error al realizar la solicitud a la API de companies:", error);
      });
    }

    const saveUser = async () => {
      await axios.post(`${process.env.VUE_APP_RUTA_API}api/admin`, formData.value,
        {headers: {'x-token': token.value}}
        ).then((response) => {
          msgError.value = response.data.msg;
          router.push({name: "listUsers"});
      })
      .catch((error) => {
        msgError.value = error.response.data
        console.error("Error al realizar la solicitud a la API:", error);
      });
    }

    onMounted(() => {
      fetchRoles();
      fetchCompanies();
    })
    return {
      formData,
      roles,
      companies,
      msgError,
      saveUser,
  }

  }
};
</script>
