<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Usuarios</h6>
        <button
            class="bg-blue-800 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button"
        >
          <router-link class="text-white text-decoration-none" :to="{name: 'createUser'}">

            Create
          </router-link>
        </button>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <div class="alert alert-danger text-center mx-5" v-if="users.msgError" role="alert">
          {{ users.msgError }}
      </div>
      <div class="block w-full overflow-x-auto text-center mx-1" v-if="!users.msgError && users.users">
        <table id="tableComponent" class="table table-bordered table-striped my-3 border-1">
          <thead>
            <tr>
              <!-- loop through each value of the fields to get the table header -->
              <th  v-for="field in fields" :key='field' @click="sortTable(field)" > 
                {{field}} <i class="bi bi-sort-alpha-down" aria-label='Sort Icon'></i>
              </th>
              <th>op</th>
            </tr>
          </thead>
          <tbody>
              <!-- Loop through the list get the each student data -->
            <tr v-for="user in users.users" :key='user'>
              <td v-for="field in fields" :key='field'>{{user[field]}}</td>
              <table-dropdown :userId="user.id"/>

            </tr>
          </tbody>
        </table> 
      </div>
    </div>
  </div>
      
   
</template>

<script>
  import TableDropdown from "@/components/Dropdowns/TableDropdown.vue";
  import { computed, onMounted, ref,  } from "vue";
  import { useStore } from "vuex";
export default {
    components: {
      TableDropdown,
    },
    setup () {
      const fields = [
        'firstName','lastName','userName','email','Company','Roles','state'
      ]
      const color = ref('light');
      const store = useStore();
      
      const users = computed(() => store.state.listUsers);

      onMounted(() => {
        store.dispatch('loadUsers')
      })


      return {
        fields,
        users,
        color
      }
    }
  };
  </script>