<template>
  <nav
    class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-4 z-10 py-4 px-6"
  >
    <div class="text-center d-flex flex-col">
      <ul>
        <li class="items-center">
          <router-link
            to="/dashboard"
            v-slot="{ href, navigate, isActive }"
          >
            <a
              :href="href"
              @click="navigate"
              :class="[
                isActive
                  ? 'text-green-500'
                  : 'text-blueGray-700 hover:text-blueGray-600',
              ]"                  
            >
              <i
                class="fas fa-tv text-sm md:min-w-full"
                :class="[isActive ? 'text-bold' : 'text-blueGray-500']"
              ></i>
              <span class="font-normal text-sm opacity-75">Inicio</span>
            </a>
          </router-link>
        </li>
        <hr class="m-2 hr hr-blurry">
        <li class="items-center" v-if="viewUsers">
          <router-link
            to="/admin/Users"
            v-slot="{ href, navigate, isActive }"
          >
            <a
              :href="href"
              @click="navigate"
              :class="[
                isActive
                  ? 'text-green-500'
                  : 'text-blueGray-700 hover:text-blueGray-600',
              ]"                  
            >
              <i
                class="fas fa-user-circle text-lg md:min-w-full"
                :class="[isActive ? 'text-bold' : 'text-blueGray-500']"
              ></i>
              <span class="font-normal text-sm opacity-75">Usuarios</span>
            </a>
          </router-link>
        </li>      </ul>
      
    </div>
    
  </nav>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
  export default {

    setup () {
      const store = useStore();


      const viewUsers = computed(()=> {
        let userAdmin = false
        const usuario = store.state.loginUser.user
        if (usuario.Roles) {
          userAdmin = usuario.Roles.includes('ADMIN_ROL')
        }
        return userAdmin
      });

      return {
        viewUsers
      }
    }
  }
</script>

<style scoped>
ul {
  margin: 0%;
  padding: 0%;
}
  a {
    text-decoration: none ;
  }
  hr {
    opacity: 0.2;
  }
  nav {
    margin: 0%;
    padding: 0%;
  }
</style> 