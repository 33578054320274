<template>
  <div class="dropdown">
    <button id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" >
      <i class="fas fa-ellipsis-v" ></i>
    </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <!-- <li><a class="dropdown-item" href="#" @click="editUser">Editar Usuario</a></li> -->
    <li><router-link class="dropdown-item" :to="{name: 'editUser', params: { id: userId }}">Editar Usuario</router-link></li>
    <li><a class="dropdown-item" href="#" @click="deleteUser">Desactivar Usuario</a></li>
  </ul>
</div>
</template>

<script>
const axios = require('axios');
const { useRouter } = require('vue-router');
export default {
  props: ['userId'],
  setup(props) {
    const router = useRouter();
    const deleteUser = async () => {
      await axios.delete(
          `${process.env.VUE_APP_RUTA_API}api/admin/${props.userId}`, 
          {headers: {'x-token': sessionStorage.getItem('x-token')}}
      )
      .then(response => {
        console.log('Usuario desactivado', response);
        router.push({name: "listUsers"});
      })
      .catch(error => {
        console.error('Error al obtener la lista de usuarios:', error);
      });
    } 

    return {
      deleteUser
    }
  }
}
</script>