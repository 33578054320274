<template>
    <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
    >
      <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
          <h6 class="text-blueGray-700 text-xl font-bold">Change Password</h6>
          <div>
              <button
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                @click="savePassword"
              >
                Save
              </button>
              <button
                class="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
              >
                <router-link class="text-white text-decoration-none" to="/user/dashboard">

                  Cancel
                </router-link>
              </button>

          </div>
        </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form>
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            User Information
          </h6>
          <div class="alert" :class="typeMessage" v-if="errorSaving" role="alert">
                  {{ errorSaving }}
                </div>
          <div class="flex flex-wrap">
            
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Password
                </label>
                <input
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="password"
                  @input="validatePasswords"
                  />
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Confirm Password
                </label>
                <input
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="rpassword"
                  @input="validatePasswords"
                />
                <div class="alert alert-danger" v-if="errorPassword" role="alert">
                  {{ errorPassword }}
                </div>
              </div>
            </div>
          </div>  
        </form>
      </div>
    </div>
  </template>

<script>
  import {ref, computed} from 'vue';
  import axios from 'axios';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
    export default {
      setup () {
        const password = ref('');
        const rpassword = ref('');
        const router = useRouter();
        const store = useStore();
        let errorPassword = ref('');
        let errorSaving = ref('');
        let typeMessage = ref('alert-danger');

        const userLoged = computed(() => store.state.loginUser);

        const validatePasswords = () => {
          if(password.value.length < 4) {
            errorPassword.value = 'La contrasena debe tener 5 caracteres o mas';
            return
          }
          if (password.value !== rpassword.value ) {
            errorPassword.value = 'Las contrasenas no coinciden';
          } else {
            errorPassword.value = '';
          }
        }
        const savePassword = async () => {
          if (!password.value) {
            errorSaving.value = `No se puede guardar, no hay datos aun!`
            return
          }
          if (!errorPassword.value ) {
            const id = store.state.loginUser.user.id;
            await axios.put(`${process.env.VUE_APP_RUTA_API}api/user/${id}`, {password:password.value},
            {headers: {'x-token': store.state.loginUser.token}}
            ).then((response) => {
                typeMessage.value = 'alert-success';
                errorSaving.value = `${response.data.msg} !!!`;
                router.push({path: "/user/dashboard"});
                // commit('LOGIN_SUCCESS',  {token: response.data.token, roles:  response.data.usuario.roles})
            })
            .catch((error) => {
              errorSaving.value = `No se puede guardar, ${error.response.data.msg}`
              console.error("Error al realizar la solicitud a la API:", error);
            });
            
            return
          }
          
          errorSaving.value = `No se puede guardar, ${errorPassword.value}`
          errorPassword.value = ''
        }
        return {
          password,
          rpassword,
          savePassword,
          validatePasswords,
          errorPassword,
          errorSaving,
          typeMessage,
          userLoged

        }
      }

    }
</script>
  